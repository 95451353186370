.outer-div{padding: 27px 40px 57px 40px;}
.custom-striped-table tr td{text-align: center;}
.custom-striped-table  th{text-align: center;min-height: 65px;}
.pool{ min-height: 65px;}
.tag{padding: 8px 20px;background-color: #7a29bb;color: white;border-radius: 40px;text-align: center;}
.red{color: #fd1c45 !important;}
.green{color: #4caf3b !important;}
.icon-circle{width: 35px;height: 35px;border-radius: 100%;color: white;background-color: #7a29bb;display: flex;justify-content: center;align-items: center;}
.name{color: #7a29bb;display: block !important ;}
.custom-striped-table thead{background-color: #e8e9ee;}
.custom-striped-table tbody{background-color: #f6f7f8 ;}
.custom-striped-table .table>:not(:first-child) {border-top: 1px solid #dee2e6;}
.mid{display: flex;justify-content: center;align-items: center;min-height: 80px;}
.middle{display: flex;align-items: center;min-height: 97px;}
.custom-striped-table table{--bs-table-striped-bg: #f6f7f8 !important ;}
.custom-striped-table tr a:hover{color: white;}
.custom-striped-table tr:hover{background-color: #efe4fb;}
.table-bordered>:not(caption)>*>* {border: none;}
.right-icon{transform: rotate(45deg);}
@media (max-width: 1300px) { 
  .tag{padding: 0%;}
 }
 @media (max-width: 1199px) {
	 .custom-striped-table{width:1120px;}	 
}
 @media (max-width: 1024px) { 
  .custom-striped-table td{min-width: 171px;}
 }